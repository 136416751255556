button {
	padding: 8px 20px;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
	text-transform: uppercase;
	&:hover {
		transition-timing-function: ease-in;
		transition: 0.4s;
	}
}
.learn_more {
	color: $button-text;
	background-color: $block;
	border: none;
	&:hover {
		background-color: $main;
		color: $white;
	}
}
.grey {
	color: $white;
	background-color: $lighter-text;
	border: none;
	&:hover {
		background-color: $footer;
	}
}
.grey_border {
	padding: 6px 16px;
	color: $main-text;
	background-color: $block;
	border: 2px solid $lighter-text;
	&:hover {
		background-color: $lighter-text;
		color: $white;
	}
}
.search {
	background: url('/../../img/search_icon.png') no-repeat;
	border: none;
	padding: 15px;
	&:focus {
		outline: none;
	}
}