@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto:300,400');
@import '_colours.scss';

html,
body {
	font-family: 'Montserrat', sans-serif;
	//font-family: 'Roboto', sans-serif;
	color: $main-text;
}

h1, h2, h3, h4 {
	margin: 0;
}

.from-cms {
	h1, h2, h3, h4 {
		margin: 10px 0;
	}
	h1, h2 {
		margin: 20px 0;
	}
}

header {
	#intro {
		li {
			font-family: 'Roboto', sans-serif;
			font-weight: 300;
			color: $sub-text;
			font-size: 1.1em;
		}
	}
	#collapse {
		a {
			text-transform: uppercase;
			color: $main-text;
			font-weight: 400;
			font-size: 1.1em;
			&:hover {
			}
		}
	}
	#collapse .active a {
	}
	.carousel-caption {
		h1 {
			text-transform: uppercase;
			font-size: 3.5em;
		}
	}
	.bg_img {
		h1 {
			text-transform: uppercase;
			font-size: 4em;
			text-shadow: 0px 0px 7px rgba(40, 40, 40, 0.7);
			font-weight: 700;
		}
	}
}
//home
.content {
	h3 {
		text-transform: uppercase;
		font-weight: 400;
		font-size: 1.3em;
	}
	h2 {
		text-transform: uppercase;
		font-weight: 400;
		font-size: 1.6em;
	}
	p {
		font-size: 1.1em;
		font-family: 'Roboto', sans-serif;
		font-weight: 300;
	}
	a {
		font-family: 'Roboto', sans-serif;
		font-weight: 300;
	}
	#services {
		h3 {
			margin: 20px 0!important;
		}
		p {
			color: $sub-text;
		}
	}
	#whyus {
		h2 {
			margin: 0 0 20px 0;
		}
		p {
			color: $lighter-text;
		}
	}
	#testimonials {
		.name {
			margin-top: 10px;
			font-weight: 400;
			font-size: 1.4em;
		}
		.company {
			color: $sub-text;
			line-height: 0.5em;
		}
		.quote {
			margin-top: 30px;
			font-style: italic;
		}
	}
	#blog {
		.post-text {
			padding: 20px;
			height: 225px;
			h2 {
				text-transform: uppercase;
				font-size: 1.5em;
				a {
					color: $lighter-text;
				}
			}
			p {
				font-size: 1em;
				line-height: 1.3em;
				padding-top: 10px;
			}
		}
		.date {
			font-size: 0.85em;
			font-weight: 400;
			line-height: 1em;
		}
		a {
			text-transform: uppercase;
			color: $sub-text;
			font-size: 0.75em;
			font-weight: 400;
			line-height: 1em;
			&:hover {
				text-decoration: none;
				color: $main;
			}
		}
	}
	#subscribe {
		#email {
		}
	}
}

//Services
#workshops {
	#text {
		max-hight: 320px;
		h2 {
			padding-bottom: 5px;
		}
		h4 {
			font-size: 1.1em;
			color: $sub-text;
			font-family: 'Roboto', sans-serif;
			font-weight: 300;
		}
		.name {
			text-transform: uppercase;
			font-weight: 400;
		}
		.quote {
			color: $sub-text;
		}
	}
}
#how {
	h2 {
		padding-top: 10px;
	}
	.how_list {
		ul {
			li {
				line-height: 3.4em;
				text-transform: uppercase;
				color: $sub-text;
				font-family: 'Roboto', sans-serif;
				font-weight: 300;
			}
		}
	}
	.center.header {
		color: $main;
	}
	.four_columns {
		p {
			line-height: 1.8em;
			color: $sub-text;
		}
	}
}

//Services - HR
.services_info {
	.description {
		h4 {
			color: $sub-text;
			font-family: 'Roboto', sans-serif;
			font-weight: 400;
		}
		li {
			color: $sub-text;
			font-family: 'Roboto', sans-serif;
			font-weight: 300;
			font-size: 1.1em;
		}
	}
}

//Case Studies
#case_studies {
	h3, p {
		color: $sub-text;
	}
}

//Blog Page
.single_blog, #blog_post {
	#published {
		li {
			color: $main;
			font-family: 'Roboto', sans-serif;
			font-weight: 400;
			a {
				color: $sub-text;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	a {
		color: $main-text;
		&:hover {
			text-decoration: none;
			color: $main;
		}
	}
	.continue {
		text-transform: uppercase;
		font-size: 0.9em;
		color: $main;
	}
	p {
		color: $sub-text;
	}
	#tags {
		.title {
			text-transform: uppercase;
		}
		a {
			color: $main;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.blog_category {
	color: $main-text;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}
#categories {
	h3 {
		margin-bottom: 10px;
		color: $main-text;
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
	}
	ul {
		margin-top: 15px;
		li {
			a {
				color: $sub-text;
				&:hover {
					text-decoration: none;
					color: $main;
				}
			}
		}
	}
}

//Contact
#contact_form {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	input, textarea {
		border-radius: 0;
	}
	.footer-list {
		font-size: 1.3em;
	}
	.error {
		color: $main;
	}
}

//Search
.search_result {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	a {
		color: $main;
		&:hover {
			text-decoration: none;
			color: $main-text;
		}
	}
}
.search_extract {
	color: $sub-text;
	margin-bottom: 20px;
	margin-top: 5px;
}

footer {
	li, p, a {
		color: $footer-text;
		font-family: 'Roboto', sans-serif;
		font-weight: 300;
	}
	.company_name {
		color: $light-grey;
	}
	#navigation {
		a {
			text-transform: uppercase;
			font-size: 1em!important;
			&:hover {
				text-decoration: none;
				color: $main;
			}
		}
	}
	#copyright {
		p {
			font-size: 1em!important;
		}
		a {
			font-size: 1em!important;
			text-decoration: underline;
			&:hover {
				color: $blue;
				font-weight: 300;
			}
		}
		#digitalbridge {
			text-decoration: none;
			color: $blue;
		}
	}
}
