@import 'normalize.css';
@import 'partials/_colours.scss';
@import 'partials/_typography.scss';
@import 'partials/_buttons.scss';
@import 'partials/_breakpoints.scss';
@import 'partials/_forms.scss';

html, body {
	height: 100%;
}

body {
	position: relative;
	padding-top: 120px;
}

hr {
	margin: 0;
}

.line {
	padding: 15px 0;
}

.devider.container {
	padding: 0!important;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

.row {
	margin-left: 0!important;
	margin-right: 0!important;
}

@media (min-width: 1238px) {
	.container {
    	width: 1250px;
    	.row {
    		magrin-left: 0;
    		margin-right: 0;
    	}
	}
}

@media (max-width: 1200px) {
	.container {
		padding-right: 5px!important;
		padding-left: 5px!important;
	}
	#collapse {
		.nav {
			li {
			    a {
			    	padding: 15px 10px;
			    	font-size: 1em!important;
			    }
			}
		}
	}
	#case_studies .one_row {
	    min-height: 445px!important;
	}
}
@media (max-width: 991px) {
	.last {
		margin-top: 40px;
	}
	.carousel-caption {
		width: 100%!important;
		left: 50%!important;
		top: 50%;
		transform: translate(-50%, -50%)!important;
		text-align: center!important;
		h1 {
			font-size: 2em!important;
		}
	}
    header {
		.navbar-default {
			.navbar-header {
				float: none;
				.navbar-brand {
					float: none;
					display: block;
					margin: 0 auto;
					max-width: 262px;
					img {
						width: 70%;
					}
				}
			}
		}
		.navbar .navbar-nav {
		    display: inline-block;
		    float: none!important;
	    	li {
	    		a {
	    			margin-top: 15px!important;
	    			margin-bottom: -7px;
	    		}
	    	}
		}
		.navbar .navbar-collapse {
		    text-align: center;
		}
		#intro {
			display: none!important;
		}
	}
	.content {
		#services {
			.bottom2 {
				padding-top: 30px;
			}
		}
		#whyus {
			#text {
				padding-top: 20px;
			}
		}
	}
	#workshops {
		#text {
			margin-top: 30px;
		}
	}
	.services_info {
		.description {
			margin-top: 20px;
		}
	}
	#about_simon {
		h2 {
			margin-top: 20px;
		}
	}
	footer {
		#navigation {
			li {
				display: block!important;
				float: initial!important;
				a {
					margin-left: 0!important;
				}
			}
		}
	}
	#send_message {
		margin-top: 50px;
	}
}
@media (max-width: 767px) {
	body {
		padding-top: 80px;
	}
	header {
		.carousel, .item, .active {
		    height: 350px;
		}
		.bg_img {
			height: 350px!important;
			.container {
				width: 100%;
				h1 {
					font-size: 2em;
					padding-left: 30px;
				}
			}
		}
		.navbar {
			padding: 5px 0 35px 0!important;
		}
		.navbar-nav {
			a {
				&:after {
					display: none!important;
				}
				&:hover:after {
					display: none!important;
				}
			}
		}
		.dropdown-menu {
			display: none!important;
		}
		#collapse {
			margin-top: 30px;
			.nav {
				margin-bottom: 0!important;
				li {
					a {
						padding: 10px!important;
				    	margin: 0!important;
				    	line-height: 1.3em;
				    }
				}
			}
		}
	}
	.bottom3 {
		padding-top: 30px;
	}
	#how {
		#how_img {
			float: none;
	    	margin: 30px auto 0 auto;
		}
		.four_columns {
			text-align: center;
		}
	}
	.services_intro {
		#diagram {
			margin-top: 20px;
		}
	}
	footer {
		#navigation {
			padding-top: 20px;
		}
	}
	.single_blog {
		h3 {
			margin-top: 10px;
		}
	}
	.blog_category {
		margin-top: 20px;
	}
	#blog_post {
		margin-top: 20px;
	}
}

@media (max-width: 430px) {
	.single_post_img {
	    height: 300px!important;
	}
}
//End of Breakpoints

header {
	#intro {
		ul {
			li {
				list-style-type: none;
				display: inline;
				float: right;
				margin-left: 30px;
				line-height: 2em;
			}
			img {
				margin-left: 10px;
			}
		}
	}
	.navbar {
		padding: 10px 0;
	}
	.navbar-default {
		background-color: $white;
		border: none;
		.navbar-brand {
			padding: 5px!important;
		}
		.navbar-nav {
			a {
				display: inline-block;
				line-height: 2.7em;
				&:after {
					content: '';
					display: block;
					height: 4px;
					width: 0;
					background: transparent;
					transition: width .3s ease, background-color .3s ease;
				}
				&:hover:after {
					width: 100%;
					background: $main-text;
				}
			}
			&.active {
			}
			.dropdown {
				.dropdown-toggle {
					background-color: transparent!important;
				}
				.dropdown-menu {
					border: none!important;
					border-radius: 0!important;
					left: 50%;
					top: 85%;
    				transform: translateX(-50%)!important;
    				margin-top: -16;
    				min-width: 205px;
				}
				ul {
					li {
						a {
							padding: 0 10px!important;
							margin: 0!important;
							&:hover {
								background: transparent;
							}
						}
					}
				}
			}
		}
		#collapse {
		}
		#collapse {
			.nav {
			    margin-bottom: -25px;
				clear: right;
			    li {
			    	padding: 0;
			    	a {
			    		margin-top: 30px;
			    	}
			    }
			}
		}
	}
}

//Carousel
.carousel, .item, .active {
    height: 562px;
}
.carousel-inner {
    height: 100%;
    position: relative;
}
.carousel-caption {
	position: absolute;
	top: 50%;
    transform: translateY(-50%);
	right: 10%;
	left: initial;
	width: 40%;
	text-align: left;

	.texts {
		li {
			visibility: hidden !important;
		}
	}
}
.fill {
    width: 100%;
    height: 100%;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.bg_img {
	background: $white;
	background-position: center center;
	background-repeat: no-repeat;
	height: 562px;
	background-size: cover;
	display: flex;
	color: $white;
	align-items: top left;
	.container {
		position: relative;
		padding: 0;
		h1 {
			position: absolute;
			bottom: 80px;
			padding: 0;
			margin: 0;
		}
	}
}

//Home
.content {
	.container {
		padding: 90px 0;
	}
	#services {
		text-align: center;
		button {
			margin-top: 10px;
		}
	}
	#whyus {
		background: $block;
		button {
			margin-top: 5px;
		}
		.grey_border {
			margin-left: 10px;
		}
		img {
			display: block!important;
		    max-width: 100%!important;
		    height: auto!important;
		}
	}
	#testimonials {
		text-align: center;
		.quotes {
			width: 60%;
			margin: 0 auto;
			padding: 0;
			li {
				height: auto;
				img {
					display: initial;
				}
			}
		}
	}
	#blog {
		padding-top: 0;
		.blog-post {
			height: 465px;
			background: $white;
			margin: 7px;
			-webkit-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.1);
			-moz-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.1);
			box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.1);
			.date {
				margin: 0 15px;
			}
			.read_more {
				text-align: right;
				margin: -15px 15px;
			}
		}
		.section_title {
			margin-bottom: 40px;
		}
		h3 {
			text-align: center;
		}
		.post-img {
			background: $white;
			background-position: center center;
			background-repeat: no-repeat;
			position: relative;
			height: 210px;
			background-size: cover;
			display: flex;
			color: $white;
		}
		.col1of4 {
			float: left;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			width: 25%;
			@media (max-width: 1200px) {
				width: 50%;
			}
			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}
	#subscribe {
		text-align: center;
		background: $block;
		input {
			margin-top: 20px;
		}
	}
}

//Services
#workshops {
	#text {
		.quotes {
			width: 100%;
			padding: 0;
			margin-top: 30px;
			.inner {
			}
		}
	}
	.slick-slide {
		height: initial;
	}
	.slick-dots {
		text-align: left!important;
		bottom: -20px;
		left: -7px;
	}
}
#how {
	background: $block;
	.header {
		padding-bottom: 20px;
	}
	.how_list {
		position: relative;
		ul {
			position: absolute;
			top: -12px;
			left: 10px;
			li {
				list-style-type: none;
			}
		}
	}
	.center.header {
		margin-top: 20px;
		text-align: center;
	}
}

.services_intro {
	background: $block;
	.col-sm-4 {
		img {
			display: block!important;
		    max-width: 100%!important;
		    height: auto!important;
		}
	}
}
.services_info {
	.row.last {
		margin-top: 90px;
	}
	.description {
		h2 {
			margin-bottom: 25px;
		}
		h4 {
			margin-bottom: 10px;
		}
		ul {
			margin-bottom: 10px;
		}
		li {
			margin-bottom: 5px;
		}
	}
	img {
		display: block!important;
	    max-width: 100%!important;
	    height: auto!important;
	}
}

//About
#about_info {
	text-align: center;
	p {
		margin: 0;
	}
	button {
		margin-top: 30px;
	}
	img {
		margin: 20px;
	}
}
#about_simon {
	background: $block;
	h2 {
		margin-bottom: 20px;
	}
	img {
		display: block!important;
    	max-width: 100%!important;
    	height: auto!important;
    }
}

//Case Studies
#case_studies {
	text-align: center;
	h3 {
		margin: 20px 0;
	}
	.learn_more {
		margin-top: 10px;
	}
	.one_row {
		margin-bottom: 60px;
		min-height: 400px;
		#case_img {
			position: relative;
			height: 200px;
			overflow: hidden;
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

//Case Study
.case_intro {
	h3 {
		margin-bottom: 20px!important;
	}
	img {
	    display: block !important;
	    max-width: 100% !important;
	    height: auto !important;
	}
}
#case_description {
	background: $block;
	.description {
		h2 {
			margin-bottom: 0;
		}
	}
}

//Blog Page
#categories, #published, #tags {
	li {
		list-style-type: none;
		margin-left: -40px;
	}
}
.blog_img {
	width: 280px;
    height: 280px;
    overflow: hidden;
}
.single_post_img {
	width: auto;
    height: 600px;
    overflow: hidden;
    margin-top: 20px;
}
.cropWidth {
    img {
    	height: 100%;
    }
}
.cropHeight {
    img {
    	width: 100%;
    }
}
.single_blog {
	padding-top: 20px;
	h3 {
		padding-bottom: 5px;
	}
	p {
		padding-top: 20px;
	}
	.img_container {
		padding: 0 5px;
	}
}
#blog_post {
	h2 {
		padding-bottom: 5px;
	}
	p {
		padding-top: 20px;
	}
	img {
	    display: block !important;
	    max-width: 100% !important;
	    height: auto !important;
	}
}
.blog_category {
	margin-bottom: 10px;
}

//Contact
#contact_form {
	.recaptcha {
		display: table;
		width: 250px;
	}
	button {
		margin-top: 15px;
	}
	h4 {
		margin-bottom: 20px;
	}
}

//Search
#search {
	h2 {
		margin-bottom: 50px;
		text-align: center;
	}
	ul {
		margin-left: -40px;
		li {
			list-style-type: none;
		}
	}
}

.footer-list {
		margin-left: -40px;
	li {
		list-style-type: none;
	}
	img {
		padding: 10px 10px 0 0;
	}
}
footer {
	.container {
		padding: 90px 0 40px 0;
		#navigation {
			li {
				display: inline;
				float: left;
				margin: 5px 0;
				a {
					margin-left: 12px;
				}
			}
		}
		#copyright {
			text-align: center;
			margin-top: 40px;
		}
	}
}
#footer {background: $footer;}
