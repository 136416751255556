$white: #FFFFFF;
$main-text: #252525;
$sub-text: #555555;
$lighter-text: #232323;
$main: #ff0000;
$border: #ddd;
$footer: #363636;
$footer-text: #929292;
$light-grey: #c2c2c2;
$blue: #0076a3;
$block: #ebebeb;
$button-text: #818181;